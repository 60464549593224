.start__container {
    width: 100%;
    min-height: 100dvh;
    overflow-y: visible;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #232D7D;
    color: white;
    font-family:'Sofia', sans-serif;
}

.start {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 100dvh;
}

.start_logo-area {
    width: 100%;
    height: fit-content;
    background: linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0));
}

.start__logo-image {
    height: 64px;
    width: auto;
    margin: 48px auto 24px 24px;
}

.start__content {
    padding-inline: 24px;
    margin-inline: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: calc(1000px - 48px);
}

h1 {
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 33.6px;
    margin: 0;
}

.start__paragraph {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

p {
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 24px;
    margin: 0;
}

.start__button-strategy-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.start__button-strategy {
    color: white;
    padding: 14px 32px;
    width: 100%;
    max-width: 600px;
    border: 2px solid white;
    border-radius: 48px;
    background: transparent;
    cursor: pointer;
    transition: all 0.25s;
    outline: none;
}

.start__button-strategy:hover, .start__button-strategy:focus {
    background: white;
    color: #232D7D;
}

h3 {
    margin: 0;
    font-weight: 700;
    font-size: 1rem;
    line-height: 19.2px;
}

.start__bottom-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    position: relative;
}

.start__background-image {
    width: calc(100% - 48px);
}

.start__buttons-container {
    display: flex;
    width: calc(100% - 48px);
}

.start__button {
    margin: 24px;
    color: white;
    width: calc(100% - 48px);
    max-width: 600px;
    padding: 14px 0;
    border-radius: 48px;
    border: none;
    outline: none;
    cursor: pointer;
    background: linear-gradient(90deg, #232D7D, #0296D8);
    transition: all 0.25s;
}

.start__button:hover, .start__button:focus {
    filter: brightness(0.8);
}

.start__prev-button {
    margin: 24px 0 24px 24px;
    background: rgba(0,0,0,0);
    outline: none;
    border: 2px solid #0296D8;
    border-radius: 64px;
    display: grid;
    place-items: center;
    cursor: pointer;
    transition: background 0.25s;
}

.start__prev-button img {
    max-width: 100%;
    width: 24px;
}

.start__prev-button:hover, .start__prev-button:focus {
    background: rgba(0,0,0,0.1);
}

.start__microphone-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    margin-inline: 24px;
}

.start__microphone {
    display: flex;
    padding-inline: 24px;
    gap: 24px;
    align-items: center;
}

.start__microphone-icon {
    width: 56px;
    height: 56px;
}

.start__update-prompts {
    width: 800px;
}

.start__system-prompt {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 16px;
    margin-top: 50px;
    align-items: center;
}

.start__system-prompt div {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 8px;
    text-align: left;
}

textarea {
    resize: none;
    width: 100%;
    height: 100px;
    font-family:'Arial';
    line-height: 1.5;
}

.start__prompt {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 16px;
    margin-top: 50px;
    align-items: center;
    position: relative;
}

.start__prompt > div {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 8px;
    text-align: left;
}

.start__prompt > div > div {
    display: flex;
    gap: 8px;
}

.start__system-prompt__hint {
    width: 20px;
    height: 20px;
    background-color: white;
    cursor: pointer;
    text-align: center;
    color: black;
}

.start__absolute-hint {
    display: none;
    position: absolute;
    bottom: 150px;
    left: 100px;
    font-size: 12px;
    max-width: 500px;
    background-color: rgb(201, 201, 201);
    color: black;
    box-shadow: 0px 0px 16px black;
    padding: 8px;
    word-wrap: normal;
}

.start__system-prompt__hint:hover + .start__absolute-hint {
    display: block;
}

.start__update-all {
    margin-top: 20px;
    margin-bottom: 50px;
}

.start__update-prompts-boxes {
    display: flex;
    gap: 20px;
    margin-bottom: 50px;
}

.start__content-tutorial {
    padding-inline: 24px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    gap: 24px;
    max-width: calc(1000px - 48px);
}

.start__tutorial-img {
    display: flex;
    justify-content: center;
    width: 100%;
}

.start__tutorial-img img {
    width: auto;
    max-height: 200px;
    max-width: 75%;
}

.start__tutorial-text {
    display: flex;
    margin-top: 24px;
    gap: 10px;
    max-width: 700px;
}

@media (max-width: 500px) {
    h1 {
        font-size: 1.5rem;
        line-height: 2rem;
    }

    h3 {
        font-size: 0.9rem;
    }

    p {
        font-size: 0.8rem;
        line-height: 1.2rem;
    }

    .start__content {
        margin-inline: 8px;
    }

    .start__button {
        margin: 24px 0 24px 24px;
    }

    .start__prev-button {
        margin: 24px 0;
    }
}

@media (max-height: 800px) {
    .start__tutorial-img img {
        max-height: 120px;
    }
}

@media (max-height: 680px) {
    .start__tutorial-img img {
        max-height: 90px;
    }
}

@media (max-height: 680px) {
    .start__tutorial-img img {
        max-height: 0px;
    }
}

.start__temp {
    margin: 0;
    width: 100%;
}